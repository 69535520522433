import {
    StyledBenefitsContainer,
    StyledBenefitsData,
    StyledBenefitsDescription,
    StyledEnvironmentBenefitsList,
    StyledEnvironmentBenefitsListContainer,
    StyledEnvironmentTitle,
    StyledInfo,
    StyledSectionTitle,
    StyledWrapperBenefits
} from '@/features/quote/components/environmental-benefits-section/style.ts'
import {useTranslation} from 'react-i18next'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {QuoteCalculationsType} from '@/features/quote/types.ts'
import {formatValues} from '@/features/project-details/helpers/helpers'

export const EnvironmentalBenefits = ({quoteCalculationsData}: {quoteCalculationsData: QuoteCalculationsType}) => {
    const {t} = useTranslation()
    const {environmentalBenefits} = quoteCalculationsData

    return (
        <StyledWrapperBenefits>
            <StyledBenefitsContainer>
                <StyledEnvironmentTitle>{t('quote:environment_benefits:title')}</StyledEnvironmentTitle>

                <StyledEnvironmentBenefitsListContainer>
                    <StyledEnvironmentBenefitsList justify={'space-between'}>
                        <Flexbox direction={'column'} gap={3}>
                            <StyledSectionTitle>{t('quote:environment_benefits:every_year')}</StyledSectionTitle>

                            <Flexbox gap={2}>
                                <Flexbox direction={'column'} gap={1}>
                                    <StyledBenefitsData>
                                        {formatValues(environmentalBenefits.co2AvoidedPercentage)}%
                                    </StyledBenefitsData>
                                    <StyledBenefitsDescription>
                                        Di CO<sub>2</sub> SO<sub>x</sub> NO<sub>x</sub>
                                    </StyledBenefitsDescription>
                                </Flexbox>

                                <Flexbox direction={'column'} gap={1}>
                                    <StyledBenefitsData>
                                        {formatValues(environmentalBenefits.co2AvoidedTonnes)}{' '}
                                        {t('quote:environment_benefits:tonnes')}
                                    </StyledBenefitsData>
                                    <StyledBenefitsDescription>
                                        Di CO<sub>2</sub>
                                        {t('quote:environment_benefits:consumption_avoided_every_year')}
                                    </StyledBenefitsDescription>
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>

                        <Flexbox direction={'column'} gap={3}>
                            <StyledSectionTitle>
                                {' '}
                                {t('quote:environment_benefits:for_duration_of_system')}
                            </StyledSectionTitle>

                            <Flexbox gap={2}>
                                <Flexbox direction={'column'} gap={1}>
                                    <StyledBenefitsData>
                                        {formatValues(environmentalBenefits.carKilometersAvoided)}
                                    </StyledBenefitsData>
                                    <StyledBenefitsDescription>
                                        {t('quote:environment_benefits:avoided_km')}
                                    </StyledBenefitsDescription>
                                </Flexbox>

                                <Flexbox direction={'column'} gap={1}>
                                    <StyledBenefitsData>
                                        {formatValues(environmentalBenefits.treesPlanted)}
                                    </StyledBenefitsData>
                                    <StyledBenefitsDescription>
                                        {t('quote:environment_benefits:tree_planted')}
                                    </StyledBenefitsDescription>
                                </Flexbox>

                                <Flexbox direction={'column'} gap={1}>
                                    <StyledBenefitsData>
                                        {formatValues(environmentalBenefits.flightsAvoided)}
                                    </StyledBenefitsData>
                                    <StyledBenefitsDescription>
                                        {t('quote:environment_benefits:avoided_flights')}
                                    </StyledBenefitsDescription>
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>
                    </StyledEnvironmentBenefitsList>
                </StyledEnvironmentBenefitsListContainer>

                <StyledInfo>{t('quote:environment_benefits:description')}</StyledInfo>
            </StyledBenefitsContainer>
        </StyledWrapperBenefits>
    )
}
