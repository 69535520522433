import {
    StyledComponentCardWrapper,
    StyledComponentsQuoteWrapper,
    StyledTitle
} from '@/features/quote/components/components-quote/style.ts'
import {useTranslation} from 'react-i18next'
import {QuoteCalculationsType, QuoteType} from '@/features/quote/types.ts'
import {SolarPanelIcon} from '@/features/quote/components/operation-system/icons/SolarPanelIcon.tsx'
import {InverterIcon} from '@/features/quote/components/operation-system/icons/InverterIcon.tsx'
import {BatteryIcon} from '@/features/quote/components/operation-system/icons/BatteryIcon.tsx'
import {OptimizerIcon} from '@/features/quote/components/operation-system/icons/OptimizerIcon.tsx'
import {ReactNode} from 'react'
import {formatValues} from '@/features/project-details/helpers/helpers'
import {FixingIcon} from '../operation-system/icons/FixingIcon'
import {AccessoriesIcon} from '../operation-system/icons/AccessoriesIcon'
import {ComponentCard} from './ComponentCard'
import {ComponentCategoriesE} from '@/features/catalog-components/services/catalogComponents.schema'

interface ProductType {
    id: string
    category: string
    name: string
    attributes: {
        power?: string
        description: string
    }
    pivot: {
        pivot_quantity: number
    }
    slug: string
}
interface GroupType {
    title: string
    image: ReactNode
    products: ProductType[]
}

export const ComponentsQuote = ({
    quoteData,
    quoteCalculationsData
}: {
    quoteData: QuoteType
    quoteCalculationsData: QuoteCalculationsType
}) => {
    const {t} = useTranslation()

    const titleToRender = (category: string) => {
        switch (category) {
            case ComponentCategoriesE.Panel:
                return {
                    title: t('quote:components:panel'),
                    image: <SolarPanelIcon />
                }
            case ComponentCategoriesE.Inverter:
                return {
                    title: t('quote:components:inverter'),
                    image: <InverterIcon />
                }

            case ComponentCategoriesE.Battery:
                return {
                    title: t('quote:components:battery'),
                    image: <BatteryIcon />
                }
            case ComponentCategoriesE.Cables:
                return {
                    title: t('quote:components:accessories_panels'),
                    image: <AccessoriesIcon />
                }
            case ComponentCategoriesE.WallBattery:
            case ComponentCategoriesE.Optimizer:
            case ComponentCategoriesE.Connectors:
                return {
                    title: t('quote:components:accessories_panels'),
                    image: <BatteryIcon />
                }
            case ComponentCategoriesE.PowerDistributionPanelsAC:
            case ComponentCategoriesE.PowerDistributionPanelsDC:
            case ComponentCategoriesE.PowerDistributionPanelsACDC:
            case ComponentCategoriesE.PowerDistributionPanelsOther:
                return {
                    title: t('quote:components:electrical_material'),
                    image: <OptimizerIcon />
                }
            case ComponentCategoriesE.Ballast:
            case ComponentCategoriesE.Rail:
            case ComponentCategoriesE.Triangles:
            case ComponentCategoriesE.ScrewAndBracket:
                return {
                    title: t('quote:components:fixings'),
                    image: <FixingIcon />
                }
            default:
                return {
                    title: t('tabs:Other'),
                    image: <OptimizerIcon />
                }
        }
    }
    const renderProductDescription = (product: ProductType, quoteCalculationsData: QuoteCalculationsType) => {
        const quantity = product.pivot.pivot_quantity

        switch (product.category) {
            case ComponentCategoriesE.Inverter:
                return (
                    <div key={product.id}>
                        <p>{product.name}</p>
                        <p>{formatValues(product.attributes.power)}kW</p>
                        <p>
                            {quantity} x {product.name}
                        </p>
                    </div>
                )
            case ComponentCategoriesE.Battery:
                return (
                    <div key={product.id}>
                        <p>
                            {quoteCalculationsData.effectiveAccumulation} {t('quote:components:battery_accumulation')}
                        </p>
                        <p>
                            {quantity} x {product.attributes.description}
                        </p>
                    </div>
                )
            case ComponentCategoriesE.Panel:
                return (
                    <div key={product.id}>
                        <p>
                            {quoteCalculationsData.systemSize} {t('quote:components:solar_energy')}
                        </p>
                        <p>
                            {quantity} x {product.attributes.description} {t('quote:components:panel_watt')} (
                            {product.slug})
                        </p>
                        <p>
                            {quoteCalculationsData.annualOutput} {t('quote:components:annual_kwh')}
                        </p>
                    </div>
                )
            case ComponentCategoriesE.Cables:
                return (
                    <p key={product.id}>
                        {quantity}m {product.attributes.description}
                    </p>
                )
            default:
                return (
                    <p key={product.id}>
                        {quantity} x {product.attributes.description ?? product.name}
                    </p>
                )
        }
    }

    const groupedProducts = quoteData?.products?.reduce((groups: {[key: string]: GroupType}, product: ProductType) => {
        const {title, image} = titleToRender(product.category)
        if (!groups[title]) {
            groups[title] = {
                title,
                image,
                products: []
            }
        }
        groups[title].products.push(product)
        return groups
    }, {})
    return (
        <>
            <StyledComponentsQuoteWrapper direction={'column'} gap={4}>
                <StyledTitle>{t('quote:components:title')}</StyledTitle>

                <StyledComponentCardWrapper>
                    {Object.values(groupedProducts).map(group => {
                        const {title, image, products} = group

                        return (
                            <ComponentCard
                                key={title}
                                title={title}
                                image={image}
                                description={products.map(product =>
                                    renderProductDescription(product, quoteCalculationsData)
                                )}
                            />
                        )
                    })}
                </StyledComponentCardWrapper>

                <p className={'description-story'}>{t('quote:components:description')}</p>
            </StyledComponentsQuoteWrapper>
        </>
    )
}
