import i18next from 'i18next'
import {toast} from '@utilities/toast/toast.tsx'
import {forwardRef, ReactNode, Ref, RefAttributes} from 'react'
import {ROUTE_RESELLER_INVITATION, UserRoleE, ROUTE_INSTALLER_INVITATION} from './constants'
import {ProjectAnalyticsParamType} from '@/features/project/types'
import dayjs from 'dayjs'
import {SelectValue} from '@/components/commons/select/Select'
import {ValidationError, GeneralError, ErrorHandlerProps, ErrorSingleType} from './errorTypes'
import {ProductType} from '@/features/system-configuration/types'
import {QuoteProductSchema} from '@/features/quote/services/quote.schema'
import {ProductResponseSchema} from '@/features/system-configuration/services/systemConfiguration.schema'
import {SummaryListType} from '@/features/system-configurator/types'

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

type ReactSelect = {
    label: string
    value: string | number
}
export const retrieveSelectSingleValue = (options: ReactSelect[], value: string) => {
    return options.find(option => option.value.toString() == value)
}

export const retrieveSelectMultiValues = (options: ReactSelect[], values: [string]) => {
    return options.filter(option => values.find(value => value.toString() == option.value.toString()))
}

export const getInitials = (name: string) => {
    return name.match(/\b\w/g) || []
}

export const megabytesToBytes = (megabytes: number) => megabytes * 1_000_000

/**
 * Function that throws an error, it's useful to prevent impossible states in the application.
 * Example: const id = params.id ?? raise('No id provided'), in this case id is a string instead of string | undefined
 * @param error
 */
export const raise = (error: string): never => {
    throw new Error(error)
}

export const debounce = <T extends (...args: Parameters<T>) => ReturnType<T>>(
    callback: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout>

    return (...args: Parameters<T>) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            callback(...args)
        }, delay)
    }
}

export function genericForwardRef<TRef, TProps = object>(
    render: (props: TProps, ref: Ref<TRef>) => ReactNode
): (props: TProps & RefAttributes<TRef>) => ReactNode {
    return forwardRef(render) as (props: TProps & RefAttributes<TRef>) => ReactNode
}

export const replaceThousands = (amount: number) => {
    if (isNaN(amount)) {
        return '-'
    }

    const stringAmount = amount?.toFixed(2)
    const [integerPart, decimalPart] = stringAmount.split('.')

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.')

    return `${formattedInteger},${decimalPart}`
}

export const getTotalAmount = (summaryList: SummaryListType) =>
    summaryList.reduce((acc, item) => {
        const price = +item?.finalPrice
        if (isNaN(price)) {
            return acc
        }
        return acc + price
    }, 0)

export const getTotal = (
    quoteSummary:
        | {
              item: {
                  id: string
                  label: string
                  price: string
                  finalPrice: string
              }
              quantity: number
          }[]
        | undefined
) => {
    if (!quoteSummary) {
        return 0
    }
    return quoteSummary.reduce((acc, item) => {
        const itemTotal = +item?.item?.finalPrice * item.quantity
        return acc + itemTotal
    }, 0)
}

export const convertFileToFormData = ({key, file}: {key: string; file: File}) => {
    const formData = new FormData()
    formData.append(key, file)
    return formData
}

export const getCorrectUserRoleInvitation = (pathname: string) => {
    switch (pathname) {
        case ROUTE_RESELLER_INVITATION:
            return UserRoleE.RESELLER
        case ROUTE_INSTALLER_INVITATION:
        default:
            return UserRoleE.INSTALLER
    }
}

export const formatChartYAxis = (value: number): string => {
    if (value >= 1_000_000) {
        return `${(value / 1_000_000).toFixed(1)}M`
    } else if (value >= 1_000) {
        return `${(value / 1_000).toFixed(1)}K`
    }
    return value.toString()
}

export const getDateRange = (selectedPeriod: SelectValue): ProjectAnalyticsParamType => {
    const today = dayjs()

    if (selectedPeriod.value === 'last_year') {
        const fromDate = today.startOf('year').format('YYYY-MM-DD')
        const toDate = today.endOf('year').format('YYYY-MM-DD')
        return {fromDate, toDate}
    } else {
        const fromDate = today.startOf('month').format('YYYY-MM-DD')
        const toDate = today.endOf('month').format('YYYY-MM-DD')
        return {fromDate, toDate}
    }
}

const getErrorMessage = <T extends string>(error: ValidationError<T> | GeneralError) => {
    if (typeof error === 'object' && error !== null) {
        const response = error?.response
        if (typeof response?.data === 'string') {
            return response.data
        } else {
            if (response && response.data) {
                if ('message' in response.data) {
                    return response.data.message
                } else if ('errors' in response.data && response.data.errors.length > 0) {
                    return response.data.errors[0].message
                }
            }
        }
    }
    if (error instanceof Error && error.message) {
        return error.message
    }
    return i18next.t('errors:default')
}

export const errorHandler = <T extends string>({error, setError}: ErrorHandlerProps<T>) => {
    const errorsList = (error?.response?.data as ErrorSingleType<T>)?.errors

    if (error) {
        if (errorsList?.length && setError) {
            errorsList?.map(err => {
                const field = err?.field

                return setError(
                    field,
                    {
                        type: 'backend_error',
                        message: err?.message
                    },
                    {
                        shouldFocus: true
                    }
                )
            })
        } else {
            const message = getErrorMessage(error)
            const options = {
                duration: 5000
            }
            return toast.error(message, options)
        }
    }
}

type ParamType = {
    options?: {id: string; name: string}[]
}

export const remapApiOptions = ({options = []}: ParamType) => {
    return options?.map(({id, name, ...rest}) => {
        return {
            id,
            value: String(id),
            label: name,
            extraPayload: rest as ProductType
        }
    })
}

type OptionType = {
    id: string
    name: string
}

export const remapApiOption = (option: OptionType | null | undefined) => {
    if (!option) return null

    const {id, name, ...rest} = option
    return {
        id,
        value: String(id),
        label: name,
        extraPayload: rest as ProductType
    }
}

export type Product = (typeof ProductResponseSchema)['_output']
export type QuoteProduct = (typeof QuoteProductSchema)['_output']

export const remapCustomOption = (data: Product | QuoteProduct) => {
    if (!data) return null

    if (!('pivot' in data)) {
        const product = data as Product

        return {
            id: product.id,
            value: product.id,
            label: product.name,
            extraPayload: {
                id: product.id,
                name: product.name,
                code: product.code,
                slug: product.slug,
                categoryId: product.categoryId,
                price: parseFloat(product.price?.toString() || '0').toString(),
                finalPrice: parseFloat(product.finalPrice?.toString() || '0').toString(),
                available: product.available,
                attributes: Array.isArray(product.attributes)
                    ? product.attributes.map(attr => ({
                          id: attr.id,
                          name: attr.name,
                          slug: attr.slug,
                          pivot: {
                              pivot_product_id: attr.pivot.pivot_product_id,
                              pivot_attribute_id: attr.pivot.pivot_attribute_id,
                              pivot_value_string: attr.pivot.pivot_value_string ?? '',
                              pivot_value_number: attr.pivot.pivot_value_number
                          }
                      }))
                    : []
            }
        }
    } else if (QuoteProductSchema.safeParse(data).success) {
        const quoteProduct = data as QuoteProduct
        return {
            id: quoteProduct.id,
            value: quoteProduct.id,
            label: quoteProduct.name,
            extraPayload: {
                id: quoteProduct.id,
                name: quoteProduct.name,
                code: quoteProduct.code,
                slug: quoteProduct.slug,
                categoryId: quoteProduct.categoryId,
                price: parseFloat(quoteProduct.pivot.pivot_unit_price).toString(),
                finalPrice: parseFloat(quoteProduct.pivot.pivot_final_price).toString(),
                available: quoteProduct.available,
                createdAt: quoteProduct.createdAt,
                updatedAt: quoteProduct.updatedAt,
                attributes: [
                    {
                        id: quoteProduct.id,
                        name: 'description',
                        slug: 'description',
                        pivot: {
                            pivot_product_id: quoteProduct.id,
                            pivot_attribute_id: quoteProduct.id,
                            pivot_value_string: quoteProduct.attributes.description ?? '',
                            pivot_value_number: null
                        }
                    },
                    {
                        id: quoteProduct.id,
                        name: 'power',
                        slug: 'power',
                        pivot: {
                            pivot_product_id: quoteProduct.id,
                            pivot_attribute_id: quoteProduct.id,
                            pivot_value_string: quoteProduct.attributes.power ?? '',
                            pivot_value_number: null
                        }
                    },
                    {
                        id: quoteProduct.id,
                        name: 'volt',
                        slug: 'volt',
                        pivot: {
                            pivot_product_id: quoteProduct.id,
                            pivot_attribute_id: quoteProduct.id,
                            pivot_value_string: quoteProduct.attributes.volt ?? '',
                            pivot_value_number: null
                        }
                    },
                    ...(quoteProduct.attributes.section
                        ? [
                              {
                                  id: quoteProduct.id,
                                  name: 'section',
                                  slug: 'section',
                                  pivot: {
                                      pivot_product_id: quoteProduct.id,
                                      pivot_attribute_id: quoteProduct.id,
                                      pivot_value_string: quoteProduct.attributes.section ?? '',
                                      pivot_value_number: null
                                  }
                              }
                          ]
                        : [])
                ]
            }
        }
    }
}

export const truncateText = (text: string, maxLength: number) => {
    if (text?.length > maxLength) {
        return text?.slice(0, maxLength) + '...'
    }
    return text
}
