import {Mesh, BufferGeometry, Material, NormalBufferAttributes, Object3DEventMap, Group, Quaternion} from 'three'
import {z} from 'zod'
import {
    PhotovoltaicFieldFormSchema,
    RoofAreaFormSchema,
    SystemConfigurationSchema,
    settingsFormSchema,
    PanelsGroupConfigurationSchema,
    FasteningConfigurationResponseSchema,
    ProjectSystemCalculationPayloadSchema,
    ProjectSystemCalculationResponseSchema,
    PowerDistributionPayloadSchema,
    PowerDistributionResponseSchema,
    CreatePowerDistributionResponseSchema,
    PowerDistributionStoreSchema,
    PowerDistributionProductSchema,
    SelectSchema,
    UserDataSchema,
    StoredGroupOfPanelsMatrixTypeSchema,
    StoredMatrixTypeSchema,
    ProductionOptionsSchema,
    ProductResponseSchema,
    EditSystemConfigurationPayloadSchema,
    ProductsAttributeSchema
} from './services/systemConfiguration.schema'
import {LocationType} from '../project/types'
import {CerberoSystemInfoDataE} from './fixtures/systemInfoData'

export enum PanelsGridE {
    Panel = 'panel',
    Empty = 'empty',
    Grid = 'grid',
    MoveButton = 'moveButton',
    RotateButton = 'rotateButton',
    Controllers = 'controllers',
    DeleteRoofSegmentButton = 'deleteRoofSegmentButton'
}

export type PanelsMatrixType = (Mesh<
    BufferGeometry<NormalBufferAttributes>,
    Material | Material[],
    Object3DEventMap
> | null)[][]

export type GroupOfPanelsMatrix = {
    groupId: string
    matrix: PanelsMatrixType | undefined
}

export enum CellTypesE {
    TopLeft = 'topLeft',
    TopRight = 'topRight',
    BottomLeft = 'bottomLeft',
    BottomRight = 'bottomRight',
    Grid = 'grid'
}

export type PanelOptionsType = {
    label: string
    value: string
}

export type PanelInfoType = {
    currentPanelNumber: number
    maxPanelNumber: number
    groupCount: number
}

export type SurfaceAreaType = {
    firstRoofMeasure: number
    secondRoofMeasure: number
    roofOrientation: OrientationE
}

export enum PlacementInfoE {
    roof = 'roof',
    ground = 'ground'
}

export enum TypologyTypesE {
    Floor = 'floor',
    Roof = 'roof'
}

export enum OrientationE {
    Vertical = 'vertical',
    Horizontal = 'horizontal'
}

export enum RoofTypeE {
    Flat = 'flat',
    Pitched = 'pitched'
}

export enum BallastModelType {
    Standard = 'standard',
    Universal = 'universal'
}

export enum TriangleTypeE {
    Large = 'large',
    Small = 'small'
}

export enum SystemTypeE {
    Cerbero = 'cerbero'
}

export enum HelperObjectTypeE {
    Circle = 'circle',
    Line = 'line',
    PlaneHelper = 'plane_helper'
}

export type PanelsGroupType = {
    [groupId: string]: Group
}

export type CirclePosition = {
    x: number
    y: number
    z: number
}

export type SegmentDataType = {
    segmentId: number // Adjust according to your actual ID type
    circlesPosition: CirclePosition[]
}

export type AdaptedGroupPanelsType = {
    groupId: string
    panels: (LocationType & {quaternion: Quaternion})[]

    groupName?: number
}

export type PhotovoltaicFieldFormType = z.infer<typeof PhotovoltaicFieldFormSchema>

export type FormSchemaType = z.infer<typeof settingsFormSchema>
export type PanelsGroupConfigurationType = z.infer<typeof PanelsGroupConfigurationSchema>
export type SystemConfigurationResponseType = z.infer<typeof SystemConfigurationSchema>
export type EditSystemConfigurationPayloadType = z.infer<typeof EditSystemConfigurationPayloadSchema>
export type RoofAreaSchemaType = z.infer<typeof RoofAreaFormSchema>
export type FasteningConfigurationResponseType = z.infer<typeof FasteningConfigurationResponseSchema>
export type SystemConfigurationPayloadType = z.infer<typeof ProjectSystemCalculationPayloadSchema>
export type ProjectSystemCalculationResponseType = z.infer<typeof ProjectSystemCalculationResponseSchema>
export type PowerDistributionPayloadType = z.infer<typeof PowerDistributionPayloadSchema>
export type PowerDistributionResponseType = z.infer<typeof PowerDistributionResponseSchema>
export type CreatePowerDistibutionResponseType = z.infer<typeof CreatePowerDistributionResponseSchema>
export type PowerDistributionStoreType = z.infer<typeof PowerDistributionStoreSchema>
export type PowerDistributionProductType = z.infer<typeof PowerDistributionProductSchema>
export type ExtendedSelectType = z.infer<typeof SelectSchema>
export type ProductionCalculationPayload = z.infer<typeof ProductionOptionsSchema>
export type ProductType = z.infer<typeof ProductResponseSchema>
export type ProductAttributeType = z.infer<typeof ProductsAttributeSchema>

//PANELS
export type UserDataType = z.infer<typeof UserDataSchema>
export type StoredMatrixType = z.infer<typeof StoredMatrixTypeSchema>
export type StoredGroupOfPanelsMatrixType = z.infer<typeof StoredGroupOfPanelsMatrixTypeSchema>

export type CerberoSystemInfoType = {
    [CerberoSystemInfoDataE.FieldPower]?: string | null
    [CerberoSystemInfoDataE.AccumulationCapacity]?: string | null
    [CerberoSystemInfoDataE.PanelPower]?: string | null
}
