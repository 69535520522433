import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StyledChartsSubtitle} from '@/features/quote/components/saving-quote/style.ts'
import {
    StyledLegend,
    StyledLegendWrap,
    StyledPerformanceChart
} from '@/features/quote/components/performance-chart/performance-chart/style.ts'
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis} from 'recharts'
import {useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {QuoteCalculationsType} from '@/features/quote/types.ts'
import {formatChartYAxis} from '@/utilities/helpers'

export const SecondChartColumn = ({
    cumulativeSavings
}: {
    cumulativeSavings: QuoteCalculationsType['cumulativeSavings']
}) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <Flexbox style={{width: '100%', height: 400}} direction={'column'} gap={4}>
            <StyledChartsSubtitle>{t('quote:saving:saving_first_year')}</StyledChartsSubtitle>

            <StyledPerformanceChart direction="column" gap={3}>
                <StyledLegendWrap gap={2}>
                    <StyledLegend gap={1} align="center" color={'#FDE272'}>
                        <span />
                        <p>{t('quote:saving:compensation')}</p>
                    </StyledLegend>

                    <StyledLegend gap={1} align="center" color={'#6CB691'}>
                        <span />
                        <p>{t('quote:saving:savings')}</p>
                    </StyledLegend>
                </StyledLegendWrap>

                <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={cumulativeSavings}>
                        <CartesianGrid vertical={false} stroke={theme.palette.primary[100]} />
                        <XAxis
                            dataKey="year"
                            fontSize={12}
                            stroke={theme.palette.primary[600]}
                            strokeWidth={0}
                            label={{
                                value: t('quote:saving:years'),
                                fill: theme.palette.primary[600],
                                position: 'insideBottom',
                                offset: -10,
                                fontSize: 14
                            }}
                        />
                        <YAxis
                            fontSize={12}
                            stroke={theme.palette.primary[600]}
                            strokeWidth={0}
                            label={{
                                value: t('quote:saving:monthly_saving'),
                                angle: -90,
                                position: 'insideLeft',
                                dx: -10,
                                dy: -20,
                                style: {textAnchor: 'middle', fill: theme.palette.primary[600]}
                            }}
                            tickFormatter={value => formatChartYAxis(value)}
                        />
                        <Bar dataKey="annualSaving" stackId="a" fill="#82ca9d" />
                        <Bar dataKey="annualCompensation" stackId="a" fill="#ffc658" radius={[10, 10, 0, 0]} />
                    </BarChart>
                </ResponsiveContainer>
            </StyledPerformanceChart>
        </Flexbox>
    )
}
