import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ReferenceLine,
    BarProps
} from 'recharts'
import {StyledFinancialImpactChart, StyledLegend, StyledLegendWrap} from './style'
import {useTheme} from 'styled-components'
import {useTranslation} from 'react-i18next'
import {CustomBar} from '../custom-bar/CustomBar'
import {CustomTooltip} from '../custom-tooltip/CustomTooltip'
import {QuoteCalculationsType} from '@/features/quote/types.ts'
import {formatValues} from '@/features/project-details/helpers/helpers'

export const FinancialImpactChart = ({
    solarFinancials
}: {
    solarFinancials: QuoteCalculationsType['solarFinancials']
}) => {
    const theme = useTheme()
    const {t} = useTranslation()

    const minValue = Math.min(...solarFinancials.map(item => item.cumulativeNetImpact))
    const maxValue = Math.max(...solarFinancials.map(item => item.cumulativeNetImpact))
    const amortizationIndex = Number(solarFinancials.findIndex(item => item.cumulativeNetImpact === 0)) + 1

    const yAxisTicks = Array.from({length: 11}, (_, i) => (minValue + (maxValue - minValue) * (i / 10)).toFixed(0))

    return (
        <StyledFinancialImpactChart direction="column" align="center" gap={4}>
            <StyledLegendWrap gap={2}>
                <StyledLegend gap={1} align="center" color={'#FDE272'}>
                    <span />
                    <p>{t('quote:financial_impact:first_legend')}</p>
                </StyledLegend>

                <StyledLegend gap={1} align="center" color={theme.palette.primary[500]}>
                    <span />
                    <p>{t('quote:financial_impact:second_legend')}</p>
                </StyledLegend>
            </StyledLegendWrap>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={solarFinancials}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <XAxis
                        fontSize={12}
                        stroke={theme.palette.primary[600]}
                        strokeWidth={0}
                        label={{
                            value: t('quote:financial_impact:x_axis_label'),
                            fill: theme.palette.primary[600],
                            position: 'insideBottom',
                            offset: -10,
                            fontSize: 14
                        }}
                    />
                    <YAxis
                        fontSize={12}
                        stroke={theme.palette.primary[600]}
                        strokeWidth={0}
                        label={{
                            value: t('quote:financial_impact:y_axis_label'),
                            fill: theme.palette.primary[600],
                            position: 'insideBottomLeft',
                            angle: -90,
                            offset: 0,
                            fontSize: 14
                        }}
                        ticks={yAxisTicks}
                        tickFormatter={value => formatValues(value)}
                    />

                    <CartesianGrid vertical={false} stroke={theme.palette.primary[100]} />
                    <Tooltip cursor={false} content={<CustomTooltip amortizationIndex={amortizationIndex} />} />
                    <ReferenceLine y={0} stroke={theme.palette.primary[100]} />
                    <Bar dataKey="cumulativeNetImpact" shape={(props: BarProps) => <CustomBar {...props} />} />
                </BarChart>
            </ResponsiveContainer>
        </StyledFinancialImpactChart>
    )
}
