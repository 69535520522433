import * as routeNames from './routeNames'

export const routes = {
    LOGIN: {
        path: routeNames.ROUTE_LOGIN,
        lazy: async () => await import('@pages/public-pages/login/Login.tsx'),
        handle: {
            path: routeNames.ROUTE_LOGIN,
            paramName: '',
            name: 'routeNames:login'
        }
    },
    PASSWORD_RECOVERY: {
        path: routeNames.ROUTE_PASSWORD_RECOVERY,
        lazy: async () => await import('@/pages/public-pages/password-recovery/PasswordRecovery.tsx'),
        handle: {
            path: routeNames.ROUTE_PASSWORD_RECOVERY,
            paramName: '',
            name: 'routeNames:passwordRecovery'
        }
    },
    RESET_PASSWORD: {
        path: routeNames.ROUTE_RESET_PASSWORD,
        lazy: async () => await import('@pages/public-pages/reset-password/ResetPassword.tsx'),
        handle: {
            path: routeNames.ROUTE_RESET_PASSWORD,
            paramName: 'token',
            name: 'routeNames:resetPassword'
        }
    },
    HOME: {
        path: routeNames.ROUTE_HOME,
        lazy: async () => await import('@pages/all-user-role-pages/home/Home.tsx'),
        handle: {
            path: routeNames.ROUTE_HOME,
            paramName: '',
            name: 'routeNames:home'
        }
    },
    PROJECTS: {
        path: routeNames.ROUTE_PROJECTS,
        lazy: async () => await import('@/pages/all-user-role-pages/projects/Projects.tsx'),
        handle: {
            path: routeNames.ROUTE_PROJECTS,
            paramName: '',
            name: 'routeNames:projects'
        }
    },
    PROJECT_DETAILS: {
        path: routeNames.ROUTE_PROJECT_DETAILS,
        lazy: async () => await import('@/pages/installer-pages/project-details/ProjectDetails.tsx'),
        handle: {
            path: `/projects/:projectId`,
            paramName: 'projectId',
            name: ''
        }
    },
    INSTALLERS: {
        path: routeNames.ROUTE_INSTALLERS,
        lazy: async () => await import('@pages/reseller-pages/installers/Installers.tsx'),
        handle: {
            path: routeNames.ROUTE_INSTALLERS,
            paramName: '',
            name: 'routeNames:installers'
        }
    },
    INSTALLER_DETAILS: {
        path: routeNames.ROUTE_INSTALLER_DETAILS,
        lazy: async () => await import('@/pages/reseller-pages/installers/installer-details/InstallerDetails.tsx'),
        handle: {
            path: routeNames.ROUTE_INSTALLER_DETAILS,
            paramName: 'installerId',
            name: ''
        }
    },
    RESELLERS: {
        path: routeNames.ROUTE_RESELLERS,
        lazy: async () => await import('@pages/admin-pages/resellers/Resellers.tsx'),
        handle: {
            path: routeNames.ROUTE_RESELLERS,
            paramName: '',
            name: 'routeNames:resellers'
        }
    },
    RESELLER_DETAILS: {
        path: routeNames.ROUTE_RESELLER_DETAILS,
        lazy: async () => await import('@pages/admin-pages/reseller-details/ResellerDetails.tsx'),
        handle: {
            path: routeNames.ROUTE_RESELLER_DETAILS,
            paramName: 'resellerId',
            name: ''
        }
    },
    RESELLER_PRODUCTS: {
        path: routeNames.ROUTE_RESELLER_PRODUCTS,
        lazy: async () => await import('@pages/admin-pages/reseller-price-list/PriceList.tsx'),
        handle: {
            path: routeNames.ROUTE_RESELLER_PRODUCTS,
            paramName: 'resellerId',
            name: ''
        }
    },
    RESELLER_INVITATION: {
        path: routeNames.ROUTE_RESELLER_INVITATION,
        lazy: async () => await import('@/pages/reseller-pages/reseller-invitation/ResellerInvitation'),
        handle: {
            path: routeNames.ROUTE_RESELLER_INVITATION,
            paramName: 'token',
            name: ''
        }
    },
    RESELLER_LIST: {
        path: routeNames.ROUTE_LIST,
        lazy: async () => await import('@/pages/reseller-pages/resellers-list/ResellersList.tsx'),
        handle: {
            path: routeNames.ROUTE_LIST,
            paramName: '',
            name: ''
        }
    },
    INSTALLER_LIST: {
        path: routeNames.ROUTE_INSTALLER_LIST,
        lazy: async () => await import('@/pages/reseller-pages/resellers-list/ResellersList.tsx'),
        handle: {
            path: routeNames.ROUTE_INSTALLER_LIST,
            paramName: 'installerId',
            name: ''
        }
    },
    INSTALLER_INVITATION: {
        path: routeNames.ROUTE_INSTALLER_INVITATION,
        lazy: async () => await import('@/pages/installer-pages/installer-invitation/InstallerInvitation'),
        handle: {
            path: routeNames.ROUTE_INSTALLER_INVITATION,
            paramName: 'token',
            name: ''
        }
    },
    PRODUCTS: {
        path: routeNames.ROUTE_PRODUCTS,
        lazy: async () => await import('@/pages/reseller-pages/price-list/PriceList.tsx'),
        handle: {
            path: routeNames.ROUTE_PRODUCTS,
            paramName: '',
            name: 'routeNames:priceList'
        }
    },
    DISCOUNTS: {
        path: routeNames.ROUTE_DISCOUNTS,
        lazy: async () => await import('@pages/reseller-pages/discounts/Discounts.tsx'),
        handle: {
            path: routeNames.ROUTE_DISCOUNTS,
            paramName: '',
            name: 'routeNames:discounts'
        }
    },
    SYSTEM_TEMPLATE: {
        path: routeNames.ROUTE_SYSTEM_TEMPLATE,
        lazy: async () => await import('@/pages/installer-pages/system-template/SystemTemplate'),
        handle: {
            path: routeNames.ROUTE_SYSTEM_TEMPLATE,
            paramName: '',
            name: ''
        }
    },
    SYSTEM_CONFIGURATOR: {
        path: routeNames.ROUTE_SYSTEM_CONFIGURATOR,
        lazy: async () => await import('@pages/installer-pages/system-configurator/SystemConfigurator.tsx'),
        handle: {
            path: routeNames.ROUTE_SYSTEM_CONFIGURATOR,
            paramName: 'projectId, projectSystemId',
            name: 'routeNames:systemConfiguration'
        }
    },
    CERBERO_CONFIGURATION: {
        path: routeNames.ROUTE_CERBERO_CONFIG,
        lazy: async () => await import('@/pages/installer-pages/cerbero-configurator/CerberoSystem'),
        handle: {
            path: routeNames.ROUTE_CERBERO_CONFIG,
            paramName: 'projectId, projectSystemId',
            name: 'routeNames:systemConfiguration'
        }
    },
    INSTALLER_PRODUCTS: {
        path: routeNames.ROUTE_INSTALLER_PRODUCTS,
        lazy: async () => await import('@pages/installer-pages/installer-products/InstallerProducts.tsx'),
        handle: {
            path: routeNames.ROUTE_INSTALLER_PRODUCTS,
            paramName: '',
            name: ''
        }
    },

    INSTALLER_DISCOUNTS: {
        path: routeNames.ROUTE_INSTALLER_DISCOUNTS,
        lazy: async () => await import('@pages/installer-pages/installer-discounts/InstallerDiscounts.tsx'),
        handle: {
            path: routeNames.ROUTE_INSTALLER_DISCOUNTS,
            paramName: '',
            name: ''
        }
    },
    QUOTE: {
        path: routeNames.ROUTE_QUOTE,
        lazy: async () => await import('@pages/quote/Quote.tsx'),
        handle: {
            path: routeNames.ROUTE_QUOTE,
            paramName: 'quoteId',
            name: ''
        }
    },
    QUOTE_PDF: {
        path: `${routeNames.ROUTE_QUOTE}?pdf=true`,
        lazy: async () => await import('@pages/quote/QuotePdf'),
        handle: {
            path: routeNames.ROUTE_QUOTE,
            paramName: 'quoteId',
            name: ''
        }
    }
}
