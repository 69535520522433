import {EMAIL_MAX_CHARS, INPUT_TEXT_MAX_CHARS} from '@/utilities/constants/input'
import {PASSWORD_REGEX, PHONE_REGEX} from '@/utilities/constants/regex'
import {z} from 'zod'

export const enum PROFILE_USER_MODEL {
    FirstName = 'firstName',
    LastName = 'lastName',
    PhoneNumber = 'phoneNumber',
    Email = 'email',
    Qualification = 'qualification',
    CompanyName = 'companyName',
    VatNumber = 'vatNumber',
    Address = 'address',
    Token = 'token',
    LogoImage = 'logoImagePath',
    CompanyId = 'companyId',
    CompanyVatNumber = 'companyVatNumber',
    CompanyAddress = 'companyAddress'
}

export const enum CHANGE_PASSWORD_MODEL {
    CurrentPassword = 'currentPassword',
    NewPassword = 'password',
    NewPasswordConfirmation = 'password_confirmation'
}

export const EditProfileValidationSchema = z.object({
    [PROFILE_USER_MODEL.FirstName]: z
        .string()
        .min(1, {message: 'errors:name_required'})
        .max(INPUT_TEXT_MAX_CHARS, {message: `errors:text_too_long`}),
    [PROFILE_USER_MODEL.LastName]: z
        .string()
        .min(1, {message: 'errors:surname_required'})
        .max(INPUT_TEXT_MAX_CHARS, {message: `errors:text_too_long`}),
    [PROFILE_USER_MODEL.PhoneNumber]: z
        .string()
        .min(1, {message: 'errors:phone_required'})
        .min(10, {message: 'errors:phone_too_short'})
        .max(15, {message: 'errors:phone_too_long'})
        .regex(PHONE_REGEX, {message: 'errors:invalid_phone_number'}),
    [PROFILE_USER_MODEL.Email]: z
        .string()
        .min(1, {message: 'errors:email'})
        .email({message: 'errors:email_valid'})
        .max(EMAIL_MAX_CHARS, {message: 'errors:email_too_long'}),
    [PROFILE_USER_MODEL.Qualification]: z.string().optional(),
    [PROFILE_USER_MODEL.CompanyName]: z.string().min(1, {message: 'errors:agency_required'}),
    [PROFILE_USER_MODEL.VatNumber]: z.string()
})

export const ChangePasswordValidationSchema = z
    .object({
        [CHANGE_PASSWORD_MODEL.CurrentPassword]: z.string().min(1, 'errors:required_password'),
        [CHANGE_PASSWORD_MODEL.NewPassword]: z
            .string()
            .min(6, 'errors:password_to_short')
            .max(32, 'errors:password_to_long')
            .regex(new RegExp(PASSWORD_REGEX), {message: 'errors:password_complex'}),
        [CHANGE_PASSWORD_MODEL.NewPasswordConfirmation]: z
            .string()
            .min(6, 'errors:password_to_short')
            .max(32, 'errors:password_to_long')
            .regex(new RegExp(PASSWORD_REGEX), {message: 'errors:password_complex'})
    })
    .refine(data => data[CHANGE_PASSWORD_MODEL.NewPassword] === data[CHANGE_PASSWORD_MODEL.NewPasswordConfirmation], {
        message: `errors:password_match`,
        path: [CHANGE_PASSWORD_MODEL.NewPasswordConfirmation]
    })
