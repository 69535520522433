import {createBrowserRouter} from 'react-router-dom'
import {PrivateRoute} from '@route-guards/private-route/PrivateRoute.tsx'
import {PublicRoute} from '@route-guards/public-route/PublicRoute.tsx'
import {PublicLayout} from '@layouts/public-layout/PublicLayout.tsx'
import {PrivateLayout} from '@layouts/private-layout/PrivateLayout.tsx'
import {UserRoleE, routes} from '@utilities/constants'
import RoleBasedRoute from './route-guards/role-based-route/RoleBasedRoute'
import {CommonLayout} from './layouts/common-layout/CommonLayout'
import {NotFound} from './pages/not-found/NotFound'
import QuoteWrapper from './pages/quote/QuoteWrapper'

const router = createBrowserRouter([
    {
        element: <PrivateRoute />,
        children: [
            {
                element: <PrivateLayout />,
                children: [
                    {
                        element: <CommonLayout />,
                        children: [
                            {...routes.HOME},
                            {...routes.PROJECTS},
                            {...routes.PROJECT_DETAILS},
                            {...routes.SYSTEM_TEMPLATE},
                            {...routes.SYSTEM_CONFIGURATOR},
                            {...routes.CERBERO_CONFIGURATION},

                            {
                                element: <RoleBasedRoute grantedRoles={[UserRoleE.SUPPLIER, UserRoleE.RESELLER]} />,
                                children: [{...routes.PRODUCTS}, {...routes.DISCOUNTS}]
                            },

                            {
                                element: <RoleBasedRoute grantedRoles={[UserRoleE.INSTALLER]} />,
                                children: [{...routes.INSTALLER_PRODUCTS}, {...routes.INSTALLER_DISCOUNTS}]
                            },
                            {
                                element: <RoleBasedRoute grantedRoles={[UserRoleE.SUPPLIER]} />,
                                children: [
                                    {...routes.RESELLERS},
                                    {...routes.RESELLER_DETAILS},
                                    {...routes.RESELLER_LIST},
                                    {...routes.RESELLER_PRODUCTS}
                                ]
                            },
                            {
                                element: <RoleBasedRoute grantedRoles={[UserRoleE.RESELLER]} />,
                                children: [
                                    {...routes.INSTALLERS},
                                    {...routes.INSTALLER_DETAILS},
                                    {...routes.INSTALLER_LIST}
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        element: <PublicRoute />,
        children: [
            {
                element: <PublicLayout />,
                children: [
                    {...routes.LOGIN},
                    {...routes.PASSWORD_RECOVERY},
                    {...routes.RESET_PASSWORD},
                    {...routes.RESELLER_INVITATION}
                ]
            }
        ]
    },
    {
        path: routes.QUOTE.path,
        element: <QuoteWrapper />,
        handle: routes.QUOTE.handle
    },
    {...routes.INSTALLER_INVITATION},
    {path: '*', element: <NotFound />}
])

export default router
